import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ThanksPage = () => (
    <Layout>
        <Seo title='Thanks' />
        <div className='content-wrapper'>
            <h1>
                Thank You
            </h1>

            <p>Thank you for submitting an inquiry. We'll be in touch shortly.</p>

            <br />
            <br />
            <br />
            <Link
                to='/contact'
                className="link-button"
            >
                GET IN TOUCH
            </Link>
        </div>
    </Layout>
)


export default ThanksPage